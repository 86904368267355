:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: OpenSans;
    --alternative-font-family: Roboto, Helvetica, Arial, sans-serif;
    --font-italic: AlegreyaSans;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 0;
    
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: var(--link-color);
    --link-color: #0085BA;
    --link-hover-color: #000;
    --alternative-color: #ff0000;
}

@import "/cssmodules/normalize.css";
@import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";
@import "/fontmodules/Roboto.css";
@import "/fontmodules/AlegreyaSans.css";


/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}


/* Header
---------------------------------------------------------*/
.topsocial a svg.fa-icon {
    fill:#b8d2e9 !important;
}

.topsocial a:hover svg.fa-icon {
    fill:white !important;
}

.topsocial a {
    padding:0 0.5em;
}


.topcontact {
    padding:0.5em 0;
}

.topcontact span {
    font-family:var(--font-italic);
    display:block;
    font-style:italic;
    color:white;
    font-size:1.3rem;
    padding-right:1em;
}

.topcontact a {
    color:white;
}

.topcontact a svg.fa-icon {
    fill:white;
}

.toplogo {
    padding:1em 0;
}

.topmenu .container {
    border-top:var(--link-color) solid 1px;
}

header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 60px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:white;
    z-index:100;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown li a {
    padding:var(--topmenu-li-a-padding);
    color:#000;
    border-right:none;
    border-bottom:#eee solid 1px;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:var(--topmenu-bg);
    color:#b8d2e9;
}

.menu .sub-menu-items li a {
    margin:0;
}

ul.menu > .sub-menu > a:after {
content: "▾";
    position: absolute;
    right: 0px;
    top: 13px;
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 0.8em;
}

ul.menu > .sub-menu > ul > .sub-menu > a:after {
content: "▸";
    position: absolute;
    right: 5px;
    top: 13px;
    font-size: 19.2px;
    font-size: 1.2rem;
    line-height: 0.8em;
}


.logo {
    padding:0;
}

.logo a {
    font-size:0;
}


/* bildspel
---------------------------------------------------------*/

.start-slider {
    overflow:visible;
}

.start-slider .inner-content {
    text-align:center;
    color:white;
    max-width:600px;
    width:100%;
    margin:0 auto;
    text-shadow: 0px 0px 20px rgba(255, 255, 255, 0.4);

}

.start-slider .inner-content a {
    color:white;
}

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.slick, .slick-slide, .slick-substitute > div {
    height:100%;
    height: calc(45vh - 1px);
}

.slick-slide, .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.slick-slide, .slick-substitute > div {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.slick-title, .slick-substitute {
    text-align:center;
    color:white;
}

.slick-title h2, .slick-title h1, .slick-substitute h1 {
    display:block;
    font-size: calc(1.4 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom:0.5em;

}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--alternative-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--alternative-color);
    display:block;
}

.slick-dots li {
    border:var(--alternative-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* Content-startpage
---------------------------------------------------------*/
.block-2  {
}

.block-2 .container {
    margin-top:-100px;
}

.block-2  .box {
    font-size:0.9rem;
}

.box-1 {
    background:#0085BA;
    color:white;
}

.box-1 h2 {
    color:white;
}

.box-2 {
    background:#EAEBED;
}

.box-3 {
    background:#A3BAC3;
    color:white;
}

.box .button {
    display:block;
    color:white;
    border:#079bd6 solid 1px;
}

/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/
.content-page>.flex {
    align-items:flex-start;
}

.content-page .content-inner {
    padding:1em;
}

.art-content p:first-of-type {
    margin-top:0;
}

.art-form input[type="email"], .art-form input[type="number"], .art-form input[type="search"], .art-form input[type="text"], .art-form input[type="tel"], .art-form input[type="url"], .art-form input[type="password"], .art-form textarea {
    background:transparent;
    border-radius:0;
    border-top:0;
    border-left:0;
    border-right:0;
    border-bottom:#9fe4ff solid 1px;
}

.art-form ::-webkit-input-placeholder {
  color: #9fe4ff;
}

.art-form button[type=submit] {
    border:#9fe4ff solid 1px;
    display:block;
    width:100%;
}

.contact-text {
    font-family:var(--font-italic);
    display:block;
    font-style:italic;
    color:white;
    font-size:1.3rem;
    padding-right:1em;
}

table td p {
    margin:0;
}

table td {
    border-color:#cecece;
}

tbody tr:nth-child(even) {
   background-color: #eee;
}



/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

figure {
    margin:0;
}
    

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer h4 {
    color:white;
    font-size:1.3rem;
    letter-spacing:0;
}

footer a {
    color:white;
    text-decoration:none;
}

footer svg.fa-icon {
    fill:white;
    height:calc(1 * var(--scale-factor)rem);
}

footer svg.fa-icon:hover {
    fill: var(--link-hover-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.intenditfooter >* {
    margin: var(--margin);
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

button[type=submit], input[type=submit], .button {
    margin:0;
    background:var(--link-color);
    border:var(--link-color) solid 1px;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover {
    background: var(--link-hover-color);
    border:var(--link-hover-color) solid 1px;
}


img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}

/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
/*.flex > * > img:not([class]) {
    width: 100%;
}*/

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}

.container.lgr  {
    max-width:90%;
}

/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#fff !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .block-2 .container {
        margin-top:0;
    }
    .menu .sub-menu-items .sub-menu-items {
        position:relative;
        left:auto;
        border-left:0;
        margin:0;
        background:#fbfbfb;
    }
    .menu .sub-menu-items .sub-menu-items li {
        padding-left:0.5em;
    }
    .sub-menu > a {
        background:#eee;
    }
    .menu .dropdown li a:hover {
        background:white;
        color:#0085BA;
    }
}

@media (max-width: var(--breakpoint-s)) {
}

@media (max-width: var(--breakpoint-xs)) {
}

.Hudkomplikationer table tr {
    background-color:white;
}

.content.margin h1 {
    margin:0 0 0 0.5em;
}
